.container{
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: ; */
  align-items: flex-start;
  min-height: 70vh;
  gap: 10%;
  margin: 0 10%;
}
