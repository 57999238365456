.project-tile{
  width: 20rem;
  height: 20rem;
  border-radius: 14px;
  animation: slide-down 300ms ease-out forwards;
  margin-top: 3rem;
  /* border: 1px #D6F48D solid; */
}

.project-tile::before {
  content: '';
  /* background-image: url('../../Pages/Projects/project-logos/sketchi-logo.png'); */

  background-size: contain;
  background-repeat: no-repeat;

  position: absolute;
  top: 0;
  margin-left: 15px;
  width: 100%;
  height: 100%;

  opacity: 0.5; 
  transition: opacity 0.3s ease-in-out;

  background-image: var(--background-image-url);
}

.project-tile:hover::before {
  animation: slide-up 300ms ease-out forwards;
 
  cursor: pointer;
  opacity: 1; 
}


/* @media (min-width: 900px) {
  .project-tile::before{
    opacity: 1;
  }
} */

@media (max-width: 900px) {

  .project-tile::before{
    opacity: 1;
  }
 
}

@keyframes slide-down {
  from {
    opacity: 1;
    transform: translateY(-.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(-.5rem);
  }
}