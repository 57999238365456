.button{
  background-color: #D6F48D;
  color: #02000A;
  font-family: 'Montserrat', sans-serif;
  width: fit-content;
  padding: .5rem;
  border-radius: 5px;
  margin: 3rem 0;
  font-weight: bold;
}

.button:hover{
  background-color: #d7f48dbe;
  cursor: pointer;
}