
.snippet{
  border-radius: 5px;
  width: 90%;
  padding: 1.5rem;
  background-color: #13668a7d;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: .5px solid white;
  word-wrap: break-word;
}

code{
  font-family: monospace;
  border-radius: 5px;
  font-size: .8rem;
}

pre{
  white-space: pre-wrap;
}

.input{
  width: 95%;
}

.output{
  margin-top: 1rem;
  width: 95%;
  padding: 1rem;
  background-color: #012030;
}

.buttons{
  display: flex;
  width: 100%;
  gap: 1rem;
}

.run-button{
  width: 0;
  height: 0;
  border-top: .5rem solid transparent;
  border-bottom: .5rem solid transparent;
  border-left: 1rem solid #00900c; /* Change color as needed */
  margin-left: auto;
}

.restart-button{
  width: 1rem;
  height: 1rem;
  background-color: rgb(158, 0, 0);
}

.run-button:hover, .restart-button:hover{
  opacity: .5;
}