.footer{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 0 10%;
  bottom: 0;
  margin-bottom: 1rem;
  justify-content: flex-end;
  width: 100%;
}

.footer p {
  width: max-content;

}
