/* Hamburger Icon Styles */
.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.line {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 5px;
  transition: transform 0.3s, opacity 0.3s;
}

/* Slide-in Menu Styles */
.menu {
  display: none;
}

.menu.active {
  display: block;
  position: absolute;
  top: 60px; /* Adjust this value to control the distance from the top */
  right: 20px; /* Adjust this value to control the distance from the right */
  background-color: #02000A;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: .5px solid #F9F9DC;
  z-index: 20;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  padding: 8px 0;
}

.menu a {
  text-decoration: none;
  color: #F9F9DC;
}
