.template{
  width: 100%;
  height: 100%;
  padding: 2% 10% 5% 10%;
  word-break: normal;
  box-sizing: border-box;
  animation: slide-down 800ms ease-out forwards;
}

.template ul{
  margin-bottom: 3rem;
}

.template h1, .template h2, .template h3{
  color:#D6F48D;
}

.template img{
  border-radius: 15px;
  width: 35vw;
  border: 1px #D6F48D solid;
}

.template .img-container{
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.template div{
  max-width: 80vw;
}

.template a, .template a:visited {
  text-decoration: none;
  color: #13678A;
}

.template a:hover{
  text-decoration: underline;
  color: #D6F48D;
}


@media (min-width: 900px) {
  .template p{
    width: 33%;
  }
}

@media (max-width: 900px) {

  .title h1{
    font-size: 3.5rem;
  }

  .template img{
    border-radius: 15px;
    width: 80vw;
  }

 
}


@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(-.5rem);
  }
}